<script setup lang="ts">
import type { NotificationOpts } from 'composables/notification';


const display = ref(false);
const title   = ref("");
const message = ref("");
const type    = ref<"success" | "warning" | "error" | null>(null);

const reset = () => {
  display.value = false;
  title.value   = "";
  message.value = "";
  type.value    = null;
};

const imageUrl = computed(() => `/images/ui/notification/${type.value}.png`);

notifier.on("notification", (opts: NotificationOpts) => {

  reset(); // Clear previous notification

  display.value = true;

  title.value   = opts.title ?? "";
  message.value = opts.message;
  type.value    = opts.type ?? "info";

  setTimeout(() => { reset(); }, opts.duration || 5000);

});

</script>

<template>

  <!-- Notification Body -->
  <div class="flex justify-center w-full px-6 z-[100]">

    <Transition
      name="fade-in-left"
      appear
      mode="out-in">

      <div
        v-if="display"
        @click="reset"
        :class="{
          ['border-default-green']: type === 'success',
          ['border-[#FF9D6C]']:     type === 'warning',
          ['border-[#FF6977]']:     type === 'error',
        }"
        class="
          flex justify-left items-center w-full
          bg-black
          border-2
          px-4 py-2
        ">

        <!-- Icon -->
        <img class="size-6 mr-4" :src="imageUrl" alt="">

        <div class="-mb-1">
          <h1 class="text-sm text-white/60">{{ title }}</h1>
          <h2 class="text-sm text-white">{{ message }}</h2>
        </div>

      </div>


    </Transition>

  </div>

</template>
