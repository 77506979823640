<script lang="ts" setup>
const store = useStore();
const route = useRoute();

const noNavigationMenu = computed(() => {
  return route.path === "/levelup";
});
</script>

<template>
  <section
    class="flex flex-col justify-center items-center h-screen bg-black scrollbar-hide"
    :class="[
      ['/perks', '/earn', '/stats', '/invite'].includes(route.path) ? 'overflow-auto' : '',
    ]">

    <div class="flex flex-col w-full h-full">
      <div v-if="!store.loading" class="flex-1">
        <slot />

        <Notification class="fixed left-0 top-5"/>

        <template v-if="!noNavigationMenu">
          <NavigationMenu
            class="fixed bottom-0 left-1/2 -translate-x-1/2 z-40"
          />
          <div
            class="absolute bottom-0 left-0 h-[300px] w-full bg-gradient-to-t from-black via-black/40 to-transparent pointer-events-none z-0"
          />
        </template>
      </div>
      <Loading v-else />
    </div>
  </section>
</template>

<style></style>
