<script lang="ts" setup>

const route = useRoute();

const menus = [
  { name: "Work", icon: "work.png", link: "/" },
  { name: "Boosters", icon: "boosters.png", link: "/perks" },
  { name: "Earn", icon: "earn.png", link: "/earn" },
  { name: "Invite", icon: "invite.png", link: "/invite" },
  { name: "Stats", icon: "stats.png", link: "/stats" },
];


</script>

<template>

  <div
    class="w-full"
    :class="[
      ['/perks', '/earn', '/stats', '/invite'].includes(route.path) ? 'bg-black border-t border-white/30' : '',
    ]">

    <div class="relative px-6 w-full">

      <nav class="flex justify-between px-2 py-3 rounded-lg w-full space-x-4">

        <NuxtLink
          v-for="menu in menus"
          :to="menu.link"
          class="text-white -ml-3 flex-1 flex flex-col justify-center items-center text-sm space-y-2 z-50"
          active-class="!text-[#DDFFD2]">

          <div class="relative">

            <svg
              viewBox="0 0 61 56"
              class="text-black h-12"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 0H55.2453L61 56H5.75472L0 0Z"
                fill="currentColor"
                fill-opacity="1"
                :stroke="route.path === menu.link ? '#DDFFD2' : '#1c1c1c'"
                stroke-width="3"/>
            </svg>

            <img
              class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 size-7"
              :src="`/images/ui/menu/${menu.icon}`" />

          </div>

          <span
            style="-webkit-text-stroke: 0.5px black;"
            class="text-lg">{{ menu.name }}</span>
        </NuxtLink>

      </nav>

    </div>

  </div>

</template>

<style>
</style>
